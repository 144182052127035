import './App.css';

import React from 'react';
import logo1 from './logo-audio2000.png';
import logo2 from './logo-diapason.svg';

function App() {
  return (
    <div className="app">
      <header>
        <h1>Opération pour les acouphènes</h1>
        <div className="logos">
          <img src={logo1} className="App-logo" alt="Audio 2000" />@
          <img src={logo2} className="App-logo" alt="Diapason" />
        </div>
        <p>Jusqu'au 4 février 2020</p>
      </header>
      <section>
        <h2>En quoi ça consiste&nbsp;?</h2>
        <p>Tous les centres auditifs Audio&nbsp;2000 peuvent offrir à leurs patients la thérapie <strong>Diapason pour acouphènes</strong> pour l'achat d'une prothèse auditive d'une valeur d'au moins 1290&nbsp;euros&nbsp;TTC.</p>
      </section>
      <section>
        <h2>Diapason&nbsp;?</h2>
        <p>Diapason est un dispositif médical et une thérapie visant à soulager les acouphènes. Elle se présente sous la forme d'une application pour smartphones ou tablettes.<br /><br />Diapason propose des thérapies cognitives et comportementales, de la relaxation, de la thérapie sonore (TRT, impulsions acoustiques, masquage, etc.).</p>
      </section>
      <section>
        <h2>L'intérêt pour votre centre auditif&nbsp;?</h2>
        <ul>
          <li>Vous proposez un produit innovant pour le traitement des acouphènes, complémentaire aux aides auditives.</li>
          <li>Vous n'avez aucun frais à avancer, cela ne vous coute rien tant qu'un patient n'utilise pas sa carte Diapason.</li>
        </ul>
      </section>
      <section>
        <h2>Comment participer&nbsp;?</h2>
        <ol>
          <li>Vous envoyez le <a href="diapason-bon-de-commande.pdf">bon de commande</a> rempli et signé par email à <a href="mailto:pro@immersivetherapy.fr">pro@immersivetherapy.fr</a></li>
          <li>Vous recevrez les PLV, les dépliants et les cartes Diapason.<br /><small><em>L'application requiert un code qui est écrit sur la carte offerte au patient.</em></small></li>
          <li>Lorsque vous vendez une audioprothèse avec Diapason, nous vous facturons 89&nbsp;euros&nbsp;TTC si l'utilisateur active sa carte.</li>
        </ol>
      </section>
      <section>
        <h2>Informations complémentaires</h2>
        <ul>
          <li>Vous aurez un accès à l'application Diapason pour prendre en main la thérapie.</li>
          <li>Contactez notre équipe du lundi au vendredi, par téléphone au <a href="tel:0767771843">07.67.77.18.43</a> ou par email sur <a href="mailto:pro@immersivetherapy.fr">pro@immersivetherapy.fr</a></li>
          <li>Visitez le site de <a href="https://fr.diapason-app.com">Diapason pour acouphènes.</a></li>          
        </ul>
      </section>
    </div>
  );
}

export default App;
